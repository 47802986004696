import * as React from "react";
import {CSSProperties, useState} from "react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Button, Icon, Loader, Table} from "semantic-ui-react";
import {FailsWithPipeline, Pipeline, PipelineTestDetailsByCommit} from "./PipelineStatusModel";
import {formatDate} from "../../../../../format";
import AceEditor from "react-ace";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";

export function PipelineStatusPage() {

    const [groupedByCommit, setGroupedByCommit] = React.useState<PipelineTestDetailsByCommit[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [expandedRows, setExpandedRows] = useState<any[]>([])

    const [displayText, setDisplayText] = React.useState("")
    const [displayObject, setDisplayObject] = React.useState<any>("")

    const [selectedCommit, setSelectedCommit] = React.useState<number | null>(null)
    const [selectedTest, setSelectedTest] = React.useState<string | null>(null)
    const [selectedType, setSelectedType] = React.useState<"Commit" | "Test" | "Job" | null>(null)
    const [stackTraceMode, setStackTraceMode] = React.useState(false)

    const [latestFinishedPipeline, setLatestFinishedPipeline] = React.useState<Pipeline>(null)

    let selectedRowStyle = {backgroundColor: "rgba(0,128,128,0.2)"} as CSSProperties

    React.useEffect(() => {
        callPipelineTestDetails().then(r => {
        });
    }, []);

    React.useEffect(() => {
        setStackTraceMode(false)
    }, [displayObject]);

    async function callPipelineTestDetails() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await fetch('/internalMonitoring/getPipelineTestDetails', {
            method: 'GET',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            }
        })

        if (response.status == 200) {
            let tests = await response.json();
            console.log("[PipelineStatusPage:callPipelineTestDetails] tests: ", tests)
            let grouped = groupByCommit(tests.failsWithCausingCommits);
            console.log("[PipelineStatusPage:callPipelineTestDetails] grouped: ", grouped)

            setGroupedByCommit(grouped)
            setLatestFinishedPipeline(tests.currentPipeline)
        }

        setLoading(false)

    }

    function cleanTestSuiteName(testSuiteName: string) {
        return testSuiteName.replace("test:", "");
    }

    function groupByCommit(tests: FailsWithPipeline[]): PipelineTestDetailsByCommit[] {

        let grouped: PipelineTestDetailsByCommit[] = []

        tests.forEach((test: FailsWithPipeline) => {

            let found = grouped.find(group =>
                group.causingCommitId === test.causingCommit?.id
            );
            if (!found) {

                let newGroup = {
                    causingCommitId: test.causingCommit?.id,
                    causingCommit: test.causingCommit,
                    fails: [test]
                } as PipelineTestDetailsByCommit
                grouped.push(newGroup)
            } else {
                found.fails.push(test)
            }
        })

        console.log("[PipelineStatusPage:groupByCommit] grouped: ", grouped)
        return grouped;


    }



    enum CellStyleCategory {
        HEADER,
        SUBHEADER,
        NORMAL,
        SUBNORMAL
    }

    function getStyle(category: CellStyleCategory) {
        switch (category) {
            case CellStyleCategory.HEADER: {
                return {fontWeight: "bold", color: "black", fontSize: 18} as CSSProperties
            }
            case CellStyleCategory.SUBHEADER: {
                return {fontWeight: "bold", color: "black"} as CSSProperties
            }
            case CellStyleCategory.NORMAL: {
                return {color: "black", fontSize: 14} as CSSProperties
            }
            case CellStyleCategory.SUBNORMAL: {
                return {color: "black"} as CSSProperties
            }
        }
    }

    function cell(category: CellStyleCategory, content: any) {
        return <Table.Cell><a style={getStyle(category)}>{content}</a></Table.Cell>
    }

    function tableView() {

        function commitRow(commit: PipelineTestDetailsByCommit, index:  number) {
            function handleRowClick(rowId) {

                setLoading(true)
                const isRowCurrentlyExpanded = expandedRows.includes(rowId);

                const newExpandedRows = isRowCurrentlyExpanded
                    ? expandedRows.filter(id => id !== rowId)
                    : expandedRows.concat(rowId);

                setExpandedRows(newExpandedRows);
                setDisplayObject(groupedByCommit[rowId])
                setDisplayText(JSON.stringify(groupedByCommit[rowId], null, 4))
                setSelectedCommit(rowId)
                setSelectedType("Commit")
                setLoading(false)
            }

            const clickCallback = () => handleRowClick(index);
            let date = "unknown"
            if (commit.causingCommit != null) {
                date = formatDate(new Date(commit.causingCommit.committed_date))
            }
            return <Table.Row
                onClick={clickCallback}
                key={"row-data-" + index}
                style={selectedCommit === index ? selectedRowStyle : {}}
            >
                {cell(CellStyleCategory.NORMAL, commit.fails.length)}
                {cell(CellStyleCategory.NORMAL, commit.causingCommit?.author_name)}
                {cell(CellStyleCategory.NORMAL, date)}
                {cell(CellStyleCategory.NORMAL, commit.causingCommit?.last_pipeline?.id)}
                {cell(CellStyleCategory.NORMAL, commit.causingCommit?.message)}
            </Table.Row>
        }

        function failedTestsSubTable(commit: PipelineTestDetailsByCommit, index: number) {

            function failedTestsHeader(index: number) {
                return <Table.Row key={"row-expanded-header-" + index}>
                    {cell(CellStyleCategory.SUBHEADER, "")}
                    {cell(CellStyleCategory.SUBHEADER, "Module")}
                    {cell(CellStyleCategory.SUBHEADER, "execution time")}
                    {cell(CellStyleCategory.SUBHEADER, "class name")}
                    {cell(CellStyleCategory.SUBHEADER, "Test Name")}
                </Table.Row>
            }

            function failedTestsRow(test: any, index: number) {
                return <Table.Row
                    key={"row-expanded-" + index + "_" + test.testName}
                    style={selectedTest === test.testName ? selectedRowStyle : {}}
                    onClick={() => {
                        setDisplayObject(test.testCase)
                        setDisplayText(JSON.stringify(test.testCase, null, 4))
                        setSelectedTest(test.testName)
                        setSelectedType("Test")
                    }}
                >
                    {cell(CellStyleCategory.SUBNORMAL, "")}
                    {cell(CellStyleCategory.SUBNORMAL, cleanTestSuiteName(test.testSuite.name))}
                    {cell(CellStyleCategory.SUBNORMAL, test.testCase.execution_time)}
                    {cell(CellStyleCategory.SUBNORMAL, test.testCase.classname)}
                    {cell(CellStyleCategory.SUBNORMAL, test.testName)}
                </Table.Row>
            }

            let itemRows = []
            // FAILED TESTS
            let failedTests = commit.fails.filter(fail => {
                return fail.failedTest != null
            })

            if(failedTests.length > 0) {
                itemRows.push(failedTestsHeader(index))

                failedTests.forEach(fail => {
                    itemRows.push(
                        failedTestsRow(fail.failedTest, index)
                    )
                })
            }
            return itemRows
        }

        function failedJobsSubTable(commit: PipelineTestDetailsByCommit, index: number) {

            function failedJobsHeader(index: number) {
                return <Table.Row key={"row-expanded-header-" + index}>
                    {cell(CellStyleCategory.SUBHEADER, "")}
                    {cell(CellStyleCategory.SUBHEADER, "Job")}
                    {cell(CellStyleCategory.SUBHEADER, "duration")}
                    {cell(CellStyleCategory.SUBHEADER, "stage")}
                    {cell(CellStyleCategory.SUBHEADER, "failure reason")}
                </Table.Row>
            }

            function failedJobsRow(job: any, index: number) {
                return <Table.Row
                    key={"row-expanded-" + index + "_" + job.jobName}
                    style={selectedTest === job.jobName ? selectedRowStyle : {}}
                    onClick={() => {
                        setDisplayObject(job.job)
                        setDisplayText(JSON.stringify(job.job, null, 4))
                        setSelectedTest(job.jobName)
                        setSelectedType("Job")
                    }}
                >
                    {cell(CellStyleCategory.SUBNORMAL, "")}
                    {cell(CellStyleCategory.SUBNORMAL, job.jobName)}
                    {cell(CellStyleCategory.SUBNORMAL, job.job.duration)}
                    {cell(CellStyleCategory.SUBNORMAL, job.job.failure_reason)}
                    {cell(CellStyleCategory.SUBNORMAL, job.job.stage)}
                </Table.Row>
            }

            let itemRows = []
            let failedJobs = commit.fails.filter(fail => {
                return fail.failedJob != null
            })

            if(failedJobs.length > 0) {

                itemRows.push(failedJobsHeader(index))

                failedJobs.forEach(fail => {
                    itemRows.push(
                        failedJobsRow(fail.failedJob, index)
                    )
                })
            }
            return itemRows
        }

        let allItemRows: any[] = []

        groupedByCommit.forEach((d, index) => {

            const itemRows = [
                commitRow(d, index)
            ];

            if (expandedRows.includes(index)) {

                // FAILED TESTS
                failedTestsSubTable(d, index).forEach(row => {
                    itemRows.push(row)
                })

                // FAILED JOBS
                failedJobsSubTable(d, index).forEach(row => {
                    itemRows.push(row)
                })
            }
            allItemRows.push(itemRows)
        })
        return <Table selectable striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> failing
                        tests</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> commited by</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> commited at</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> Pipeline</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> message</a></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
        </Table>

    }

    function gitlabButton(url: string) {
        return <Button
            width={50}
            color={"orange"}
            onClick={() => window.open(url, "_blank")}
            icon={"gitlab"}
        />
    }

    function buildCommitButtons() {
        return <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            <h1>Selected Commit:</h1>
            {gitlabButton(displayObject.causingCommit.web_url)}
        </div>
    }

    function buildTestButtons() {
        return <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            <h1>Selected Test:</h1>
            {!stackTraceMode && <Button
                color={"red"}
                icon={"error"}
                onClick={() => {
                    setDisplayText(displayObject.system_output)
                    setStackTraceMode(true)
                }
                }
            >Show Stack-Trace
            </Button>}
            {stackTraceMode && <Button
                color={"green"}
                icon={"check"}
                onClick={() => {
                    setDisplayText(JSON.stringify(displayObject, null, 4))
                    setStackTraceMode(false)
                }
                }>Show complete Object
            </Button>
            }

        </div>
    }

    function displayTextArea() {

        return <>
            {selectedType === "Commit" && buildCommitButtons()}
            {selectedType === "Test" && buildTestButtons()}
            <AceEditor
                theme={"monokai"}
                style={{flex: 1}}
                mode={"json"}
                value={displayText}
                width={"100%"}
            />
        </>
    }

    return <>
        <h1>Failing Pipeline Tests: {latestFinishedPipeline &&
            <a href={latestFinishedPipeline?.webUrl}><Icon name={"gitlab"}/>{latestFinishedPipeline?.id}</a>}
        </h1>

        <Loader active={loading}/>
        <Splitter
            name={"PipelineStatusPage"}
            left={tableView()}
            right={displayTextArea()}
        />
    </>
}